class AnimateScroll {
  isRunning: boolean;

  callback: typeof Function | null;

  constructor() {
    this.isRunning = false;
    this.callback = null;
  }

  static documentVerticalScrollPosition(): number {
    if (window.scrollY) {
      return window.scrollY;
    }

    if (document.documentElement && document.documentElement.scrollTop) {
      return document.documentElement.scrollTop;
    }

    if (document.body.scrollTop) {
      return document.body.scrollTop;
    }

    return 0;
  }

  static viewportHeight(): number {
    return (document.compatMode === 'CSS1Compat')
      ? document.documentElement.clientHeight
      : document.body.clientHeight;
  }

  static documentHeight(): number {
    return document.body.scrollHeight;
  }

  static documentMaximumScrollPosition(): number {
    return AnimateScroll.documentHeight() - AnimateScroll.viewportHeight();
  }

  static elementVerticalClientPositionById(id: string): number {
    const element: HTMLElement | null = document.getElementById(id);

    if (element) {
      const rectangle = element.getBoundingClientRect();

      return rectangle.top;
    }

    return 0;
  }

  scrollVerticalTickToPosition(currentPosition: number, targetPosition: number): void {
    const filter = 0.2;
    const fps = 60;
    const difference = targetPosition - currentPosition;

    // Snap, then stop if arrived.

    const arrived = (Math.abs(difference) <= 0.5);

    if (arrived) {
      window.scrollTo(0.0, targetPosition);

      this.isRunning = false;

      if (typeof this.callback === 'function') {
        this.callback();
      }
    } else {
      const newPosition = (currentPosition * (1.0 - filter))
        + (targetPosition * filter);

      window.scrollTo(0.0, Math.round(newPosition));

      setTimeout(() => {
        this.scrollVerticalTickToPosition(newPosition, targetPosition);
      }, (1000 / fps));
    }
  }

  then(callback: typeof Function): void {
    this.callback = callback;
  }

  scrollVerticalToElementById(id = '', padding: number): AnimateScroll {
    const element = document.getElementById(id);

    if (element == null) {
      console.warn(`Cannot find element with id ${id}.`);
      return this;
    }

    let targetPosition = AnimateScroll.documentVerticalScrollPosition()
      + AnimateScroll.elementVerticalClientPositionById(id)
      - padding;

    const currentPosition = AnimateScroll.documentVerticalScrollPosition();

    const maximumScrollPosition = AnimateScroll.documentMaximumScrollPosition();

    if (targetPosition > maximumScrollPosition) {
      targetPosition = maximumScrollPosition;
    }

    if (!this.isRunning) {
      this.isRunning = true;
      setTimeout(() => {
        this.scrollVerticalTickToPosition(currentPosition, targetPosition);
      }, 1);
    }

    return this;
  }
}

export default AnimateScroll;
