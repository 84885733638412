<template>
    <header class="fixed-top" :class="{'header-small': showBackground.value}">
        <div class="container-fluid">
            <nav class="navbar navbar-light ">
                <router-link :to="{'name': 'home'}" class="navbar-brand">
                    <img
                      src="~@/assets/img/milkycheck-logo.svg"
                      alt=""
                      width="30"
                      height="24"
                      class="d-inline-block align-text-top"
                    >
                </router-link>
                <div class="d-flex">
                    <button class="nav-link btn-primary btn-share" @click="scrollToShareForm">
                        <span>
                          Erfahrung<br class="d-md-none">
                          teilen
                        </span>
                    </button>
                </div>
            </nav>
        </div>
    </header>
</template>

<script lang="ts">
import {
  defineComponent, computed, reactive, onMounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AnimateScroll from '../utils/AnimateScroll';

export default defineComponent({
  name: 'MilkyHeader',

  setup() {
    const showBackground = reactive({ value: false });
    const animateScroll = new AnimateScroll();
    const router = useRouter();
    const route = useRoute();

    const currentRouteName = computed(() => route.name);

    const checkPos = () => {
      showBackground.value = window.scrollY > (window.innerHeight * 0.125);
    };

    onMounted(() => {
      window.addEventListener('scroll', checkPos);
      checkPos();
    });

    const scrollToShareForm = () => {
      if (currentRouteName.value === 'home' || document.getElementById('share-experience')) {
        animateScroll.scrollVerticalToElementById('share-experience', 80);
      } else {
        router.push('home').then(() => {
          for (let i = 0; i < 2000; i += 100) {
            setTimeout(() => {
              animateScroll.scrollVerticalToElementById('share-experience', 80);
            }, i);
          }
        });
      }
    };

    return {
      showBackground,
      animateScroll,
      currentRouteName,
      scrollToShareForm,
    };
  },
});
</script>

<style lang="scss">
  @import "src/scss/base";

  header {
    will-change: background;
    background: transparent;
    box-shadow: none;
    transition: background .525s ease, box-shadow .525s ease;
    overflow: hidden;

    nav.navbar {
      padding-top: 0;
      padding-bottom: 0;
    }

    .navbar-brand {
      @include font-size(20px);
      padding: 1.5em 0;
      will-change: padding;
      transition: padding .525s ease;

      img {
        height: 3.5em;
        width: auto;
        will-change: height;
        transition: height .525s ease;

        @media (min-width: map_get($grid-breakpoints, 'xl')) {
          height: 5em;
        }
      }
    }

    .btn-share {
      position: relative;
      overflow: visible;
      border: none;
      background-color: transparent;
      color: #FFFFFF;
      @include korolev-heavy-italic(20px);
      padding: 0.6125em 0 0.6125em 1em;
      will-change: padding-left;
      transition: padding-left .35s ease;
      text-align: right;

      @media (min-width: map_get($grid-breakpoints, 'md')) {
        text-align: center;
        @include korolev-heavy-italic(30px);
        padding: 0.6125em 0 0.6125em 1em;
      }

      > span {
        position: relative;
        z-index: 2;
      }

      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;;
        z-index: 1;
        background-color: #D3744E;
        top: 0;
        left: 0;
        transform: skewX(-25deg);
      }

      &:after {
        left: 99%;
        width: 100vw;
      }

      &:focus, &:hover, &:active, &:active:focus {
        color: #FFFFFF;
        border: 0;
        box-shadow: none;
        background-color: transparent;
        padding-left: 1.5em;
      }
    }

    @media (max-width: map_get($grid-breakpoints, 'md') - 1px) {
      box-shadow: 0 0 10px rgb(32,25,20);
      background: rgba(32,25,20,1);

      .navbar-brand {
        padding: .5em 0;

        img {
          height: 2.5em;

          @media (min-width: map_get($grid-breakpoints, 'xl')) {
            height: 3em;
          }
        }
      }
    }

    &.header-small {
      box-shadow: 0 0 10px rgb(32,25,20);
      background: rgba(32,25,20,1);

      .navbar-brand {
        padding: .5em 0;

        img {
          height: 2.5em;

          @media (min-width: map_get($grid-breakpoints, 'xl')) {
            height: 3em;
          }
        }
      }
    }
  }
</style>
