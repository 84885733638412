
import {
  defineComponent, computed, reactive, onMounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AnimateScroll from '../utils/AnimateScroll';

export default defineComponent({
  name: 'MilkyHeader',

  setup() {
    const showBackground = reactive({ value: false });
    const animateScroll = new AnimateScroll();
    const router = useRouter();
    const route = useRoute();

    const currentRouteName = computed(() => route.name);

    const checkPos = () => {
      showBackground.value = window.scrollY > (window.innerHeight * 0.125);
    };

    onMounted(() => {
      window.addEventListener('scroll', checkPos);
      checkPos();
    });

    const scrollToShareForm = () => {
      if (currentRouteName.value === 'home' || document.getElementById('share-experience')) {
        animateScroll.scrollVerticalToElementById('share-experience', 80);
      } else {
        router.push('home').then(() => {
          for (let i = 0; i < 2000; i += 100) {
            setTimeout(() => {
              animateScroll.scrollVerticalToElementById('share-experience', 80);
            }, i);
          }
        });
      }
    };

    return {
      showBackground,
      animateScroll,
      currentRouteName,
      scrollToShareForm,
    };
  },
});
