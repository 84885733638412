import { createApp } from 'vue';
import VueSelect from 'vue-next-select';
import 'vue-next-select/dist/index.css';
import MilkyHeader from './components/MilkyHeader.vue';
import MilkyFooter from './components/MilkyFooter.vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

createApp(App)
  .use(store)
  .use(router)
  .component('MilkyHeader', MilkyHeader)
  .component('MilkyFooter', MilkyFooter)
  .component('VueSelect', VueSelect)
  .mount('#app');
