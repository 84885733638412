<template>
    <footer>
        <div class="container-fluid">
            <nav class="navbar navbar-light text-center">
                <span>
                    &copy; {{ new Date().getUTCFullYear() }} MilkyCheck
                </span>
                <div class="d-flex">
                    <router-link :to="{'name': 'privacy'}" class="nav-link">
                        Datenschutz
                    </router-link>
                    <router-link :to="{'name': 'imprint'}" class="nav-link pe-0">
                        Impressum
                    </router-link>
                </div>
            </nav>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MilkyFooter',
});
</script>

<style lang="scss">
    @import 'src/scss/base';

    footer {
        background-color: #201914;
        box-shadow: 0 0 10px #201914;
        color: #FFFFFF;
        @include korolev-bold(16px)
    }
</style>
