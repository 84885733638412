import { createStore } from 'vuex';
import MilkInterface from '@/interfaces/MilkInterface';
import axios from 'axios';
import BrandInterface from '@/interfaces/BrandInterface';

export default createStore({
  modules: {
    milks: {
      state: {
        milksStatus: 'notLoading',
        milksFetched: false,
        milks: [] as Array<MilkInterface>,
        price: {
          min: null as number | null,
          max: null as number | null,
          avg: null as number | null,
        },
      },
      mutations: {
        SET_MILKS_STATUS(state, status: string) {
          state.milksStatus = status;
        },
        SET_MILKS_FETCHED(state, fetched: boolean) {
          state.milksFetched = fetched;
        },
        SET_MILKS(state, milks: MilkInterface[]) {
          let min: number | null = null;
          let max: number | null = null;
          let avg: number | null = null;

          milks.forEach((milk: MilkInterface) => {
            avg = (avg || 0) + milk.price;

            if (min === null || min > milk.price) {
              min = milk.price;
            }

            if (max === null || max < milk.price) {
              max = milk.price;
            }
          });

          if (milks.length) {
            avg = (avg || 0) / milks.length;
          }

          const range: number = (max || 0) - (min || 0);

          state.milks = milks.map((m: MilkInterface) => {
            const milk = m;
            const diff = milk.price - (min || 0);
            const priceScore = 1 + (Math.floor((9 - ((9 * diff) / range)) * 2) / 2);

            milk.milky_ranking.price = priceScore;
            milk.user_ranking.price = priceScore;

            return milk;
          });

          state.price = {
            min,
            max,
            avg,
          };
        },
      },
      actions: {
        fetch(context) {
          axios.get<MilkInterface[]>(`${process.env.VUE_APP_API_URL}/milks`).then((response: {data: MilkInterface[]}) => {
            context.commit('SET_MILKS_STATUS', 'notLoading');
            context.commit('SET_MILKS_FETCHED', true);

            context.commit('SET_MILKS', response.data);
          });
        },
      },
      getters: {
        milks(state) {
          return [...state.milks];
        },
        byBrand(state) {
          const milks = [...state.milks];
          const milksGrouped: {[key: string]: BrandInterface} = {};

          milks.forEach((milk: MilkInterface) => {
            if (!milksGrouped[milk.brand.name]) {
              milksGrouped[milk.brand.name] = {
                ...milk.brand,
                milks: [] as MilkInterface[],
              };
            }

            const mks = milksGrouped[milk.brand.name]?.milks;

            if (mks) {
              mks.push(milk);
            }

            milksGrouped[milk.brand.name].milks = mks;
          });

          return milksGrouped;
        },
      },
    },
  },
});
