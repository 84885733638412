<template>
  <MilkyHeader></MilkyHeader>
  <router-view/>
  <MilkyFooter></MilkyFooter>
</template>

<style lang="scss">
  @import "src/scss/base";

  // Layout & components
  @import "~bootstrap/scss/root";
  @import "~bootstrap/scss/reboot";
  @import "~bootstrap/scss/type";
  @import "~bootstrap/scss/images";
  @import "~bootstrap/scss/containers";
  @import "~bootstrap/scss/grid";
  @import "~bootstrap/scss/tables";
  @import "~bootstrap/scss/forms";
  @import "~bootstrap/scss/buttons";
  @import "~bootstrap/scss/transitions";
  @import "~bootstrap/scss/dropdown";
  @import "~bootstrap/scss/button-group";
  @import "~bootstrap/scss/nav";
  @import "~bootstrap/scss/navbar";
  @import "~bootstrap/scss/card";
  @import "~bootstrap/scss/accordion";
  @import "~bootstrap/scss/breadcrumb";
  @import "~bootstrap/scss/pagination";
  @import "~bootstrap/scss/badge";
  @import "~bootstrap/scss/alert";
  @import "~bootstrap/scss/progress";
  @import "~bootstrap/scss/list-group";
  @import "~bootstrap/scss/close";
  @import "~bootstrap/scss/toasts";
  @import "~bootstrap/scss/modal";
  @import "~bootstrap/scss/tooltip";
  @import "~bootstrap/scss/popover";
  @import "~bootstrap/scss/carousel";
  @import "~bootstrap/scss/spinners";
  @import "~bootstrap/scss/offcanvas";

  @import "src/scss/typo";
  @import "src/scss/layout/container";
  @import "src/scss/components/sections";
  @import "src/scss/components/buttons";
  @import "src/scss/components/consent";
</style>
